import dynamic from 'next/dynamic'
import { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import styleConfig from '@/styles/style-config'

const CustomButton = dynamic(() => import('@/components/common/custom-button'))

const ContentTextBlock = (props) => {
  const backgroundColor = props?.backgroundColor || 'FFFFFF'
  const textColor = props?.textColor || '000000'
  const ctaLeftButtonType = props?.ctaLeftType
    ? props?.ctaLeftType?.split(' ')?.join('-')
    : 'primary'
  const ctaRightButtonType = props?.ctaRightType
    ? props?.ctaRightType?.split(' ')?.join('-')
    : 'primary'
  const buttonGap =
    !ctaLeftButtonType.includes('text') || !ctaRightButtonType.includes('text') || false

  const [headline, setHeadline] = useState(props.headline)

  useEffect(() => {
    let replaceString = props.headline && props.headline.replace('*', '<em>')
    if (replaceString) {
      const index = replaceString.lastIndexOf('*')
      replaceString =
        replaceString.slice(0, index) + replaceString.slice(index).replace('*', '&nbsp</em>')
      setHeadline(replaceString)
    }
  }, [props.headline])

  return (
    <>
      <div className="content-text-block relative w-full h-full flex items-center">
        <div className="text-center lg:text-left w-full">
          {props?.eyebrow && (
            <p className="content-text-block__eyebrow font-tertiary text-xsm mb-5">
              {props?.eyebrow}
            </p>
          )}
          {headline && (
            <h2
              className="content-text-block__headline font-primary text-xl md:text-2xl"
              style={{ color: '#' + textColor }}
              // dangerouslySetInnerHTML={{ __html: headline }}
            >
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>{props.headline}</ReactMarkdown>
            </h2>
          )}
          {props?.bodyText && (
            <div className="content-text-block__body-text text-sm">
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>{props?.bodyText}</ReactMarkdown>
            </div>
          )}
          {((props?.ctaLeftLabel && props?.ctaLeftUrl) ||
            (props?.ctaRightLabel && props?.ctaRightUrl)) && (
            <div
              className={`button-group flex flex-col justify-center items-center gap-x-10 ${
                buttonGap ? 'gap-y-5' : ''
              } md:flex-row lg:flex-col lg:items-start`}
            >
              {props?.ctaLeftLabel && props?.ctaLeftUrl && (
                <CustomButton
                  type="link"
                  label={props?.ctaLeftLabel}
                  url={props?.ctaLeftUrl}
                  size="lg"
                  variant={ctaLeftButtonType}
                />
              )}
              {props?.ctaRightLabel && props?.ctaRightUrl && (
                <CustomButton
                  type="link"
                  label={props?.ctaRightLabel}
                  url={props?.ctaRightUrl}
                  size="lg"
                  variant={ctaRightButtonType}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        .content-text-block {
          background-color: #${backgroundColor};
          color: #${textColor};
          padding: 66px 24px;
        }

        .content-text-block__eyebrow {
          line-height: 16.34px;
          letter-spacing: -0.1px;
        }

        .content-text-block__headline {
          line-height: 30px;
          letter-spacing: -0.5px;
          margin-bottom: 30px;
        }

        .content-text-block__body-text {
          line-height: 18px;
          margin-bottom: 60px;
        }

        @media (min-width: ${styleConfig.theme.screens.md}) {
          .content-text-block {
            padding: 84px 24px;
          }

          .content-text-block__headline {
            line-height: 34px;
          }
        }

        @media (min-width: ${styleConfig.theme.screens.lg}) {
          .content-text-block {
            padding: 29px 108px;
          }

          .content-text-block__headline {
            line-height: 38px;
            font-size: 40px;
          }

          .content-text-block__body-text {
            line-height: 20px;
            font-size: 16px;
          }
        }

        @media (min-width: ${styleConfig.theme.screens.xl}) {
          .content-text-block {
            padding: 103px 185px;
          }

          .content-text-block__headline {
            line-height: 53px;
            font-size: 55px;
          }
        }
      `}</style>
    </>
  )
}

export default ContentTextBlock
